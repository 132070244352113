.folderBox {
    background-color: lightgray;
    /* color: black; */
    padding: 10px;
    /* font-size: 18px; */
    border-radius: 10px;
    border-width: 10px;
    border-color: lightslategray;

}

.folderBoxInternal {
    color: black;
    /* padding: 10px; */
    font-size: 18px;
    overflow: auto;
}

.folderBoxAlignment {
    text-align: end;
    margin-bottom: 10px;
    /* vertical-align: middle; */
    /* color: red; */
}

.folderBoxDottedBottom {
    border-bottom: 2px dotted lightgray;
}

.folderBoxLeft {
    background-color: white;
    border-right: 5px solid lightgray;
    height: 200px;
}

.folderBoxRight {
    background-color: white;
    border-left: 5px solid lightgray;
    height: 200px;
}

.activeFolder {
    /* border: 3px solid red; */
    background-color: lightskyblue;
}

.folder {
    cursor: pointer;
}

.rootFolder {
    cursor: pointer;
    font-weight: bold;
}

.fp-1 {
    padding-left: 1em;
}

.fp-2 {
    padding-left: 2em;
}

.fp-3 {
    padding-left: 3em;
}

.disabledFolderBox {
    pointer-events: none;
    opacity: 0.4;
}

.documentIconHolder {
    /* margin-top: 5px; */
    width: 74px;
    height: 100px;
    border: 4px solid white;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;

    display: inline-block;
    text-align: center;

    -webkit-user-select: none;

    -moz-user-select: none;

    -khtml-user-select: none;

    -ms-user-select: none;
}

.dropTest {
    width: 100px;
    height: 100px;
    background: red;
}

.documentIconHolder:hover {
    border: 4px solid skyblue;
}

.documentIconSelected {
    border: 4px solid red;
}

.documentIcon {
    padding-top: 5px;
    font-size: 40px;
    /* width: 60px;
    height: 60px; */

}

.documentIconText {
    text-overflow: ellipsis;
    line-height: 1.3;
    font-size: 12px;
}