@import "_variables.scss";

body {
  font-family: $myFont;
  font-size: $myFontSize;
  color: $myColor;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

//NAVBAR

.navBar {
  background-color: $palDarkShade;
  box-shadow: 5px 5px 10px #999;
}

.navBarLogo {
  width: 40px;
  height: 40px;
  //transform: rotate(60deg);
}

.navBarBrand {
  color: $palLightShade;
  font-size: 1.5em;
}

.navBarLink {
  color: $palLightShade;
  font-size: 1em;
}

.navBarLink:hover {
  color: $palLightAccent;
  font-weight: bold;
}

//FOOTER

.navFooter {
  position: fixed;
  bottom: 0;
  height: 25px;
  width: 100%;
  background-color: $palDarkShade;
  //margin-top: -200px;
  box-shadow: 0px -5px 10px #999;
}

.footerMargin {
  width: 100%;
  height: 200px;
}

//Animations

.fadeIn {
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

//CARDS
.card {
  box-shadow: 3px 3px 5px #999;
}

.cardHover {
  transition: box-shadow 0.25s, border-color 0.25s;
}

.cardHover:hover {
  box-shadow: 10px 10px 8px #999;
  border-color: $palDarkShade;
}

//  .card:hover {

//  }

@media (min-width: 576px) {
  .dosboxCanvas {
    width: 510px;
  }

  .card-columns {
    column-count: 1;
  }
}

@media (min-width: 768px) {
  .dosboxCanvas {
    width: 690px;
  }

  .card-columns {
    column-count: 2;
  }
}

@media (min-width: 992px) {
  .dosboxCanvas {
    width: 930px;
  }

  .card-columns {
    column-count: 3;
  }
}

@media (min-width: 1200px) {
  .dosboxCanvas {
    width: 1110px;
  }

  .card-columns {
    column-count: 3;
  }
}

// .dosboxCanvas {
//   width: $containerWidth;
//   height: 480px;
// }

//Paralax

.parallaxTopBar {
  /* The image used */
  //background-image: url("img_parallax.jpg");

  /* Set a specific height */
  //height: 300px;
  width: 100%;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.backgroundOne {
  background: $palLightShade;
  color: $palDarkAccent;
}

.backgroundTwo {
  background: $palDarkShade;
  color: $palLightAccent;
}
