.paperCard {
    width: 80%;
    margin-bottom: 6rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.paperCardBody {
    text-align: justify;
    text-justify: inter-word;
    /* justify-content: center; */
}