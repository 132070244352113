.utilityBox {
    background-color: lightgray;
    padding: 5px;
    border-radius: 5px;
    border-width: 5x;
    border-color: lightslategray;
    font-size: 18px;
}

.utilityImageDrop {
    /* width: 100px;
    height: 80px; */
    border-radius: 3px;
    background: lightgrey;
    border-style: dashed;
    border-color: grey;
    color: grey;
    font-size: 25px;
    /* text-align: center; */
    top: 50%;
}

.utilityBarText {
    font-size: 25px;
}

/* .dropBoxDraggingtrue {
    background: lightskyblue;
    border-radius: 3px;
    border-style: double;
} */

/* 
.utilityBoxInternal {
    font-size: 18px;
    overflow: auto;
}

.folderBoxDottedBottom {
    border-bottom: 2px dotted lightgray;
}

.folderBoxLeft {
    background-color: white;
    border-right: 5px solid lightgray;
    height: 200px;
}

.folderBoxRight {
    background-color: white;
    border-left: 5px solid lightgray;
    height: 200px;
}

.activeFolder {
  
    background-color: lightskyblue;
}

.folder {
    cursor: pointer;
}

.rootFolder {
    cursor: pointer;
    font-weight: bold;
}

.fp-1 {
    padding-left: 1em;
}

.fp-2 {
    padding-left: 2em;
}

.fp-3 {
    padding-left: 3em;
} */