.holdingDiv:hover {
    /* border-color: lightgreen; */
    border-color: skyblue;
    border-style: solid;
    border-radius: 3px;
}

.holdingDiv {
    border-color: white;
    border-style: solid;
    border-radius: 3px;
}

.activeElement {
    /* border-color: green; */
    background-color: lightskyblue;
    border-style: solid;
    border-radius: 3px;
}

.inactiveElement {
    border-color: white;
    border-style: solid;
    border-radius: 3px;
}

.stickyDiv {
    position: sticky;
    background-color: lightyellow;
    top: 0;
}

.uploadDropBox {
    width: 100px;
    height: 100px;
    border-radius: 3px;
    background: lightgrey;
    border-style: dashed;
    border-color: grey;
    color: grey;
    font-size: 20px;
    text-align: center;
    top: 50%;
}

.dropBoxDraggingtrue {
    background: lightskyblue;
    border-radius: 3px;
    border-style: double;
}

.chapterDropBox {
    width: 150px;
    height: 40px;
    border-radius: 3px;
    background: lightgrey;
    border-style: dashed;
    border-color: grey;
    color: grey;
    font-size: 20px;
    text-align: center;
    top: 50%;
}