.imageBlender {
    position: relative;
    /* height: 281px;
    width: 450px; */
    width: 280px;
    height: 280px;

    margin: 0 auto;
    /* overflow: hidden; */
}

.cropped {
    width: 340px;
    height: 280px;
}

.cropped img {
    /* width: 290px;
    height: 280px; */
    width: 340px;
    height: 280px;
    margin-left: -30px;
    /* margin-right: 50px; */
}

.imageBlender img {
    position: absolute;
    left: 0;
    /* width: 340px;
    height: 280px; */

    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
}

.ibVisible {
    opacity: 1;
}

.ibHidden {
    opacity: 0;
}

/* #imageBlender img.top:hover {
    opacity: 0;
} */