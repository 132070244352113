.face3d {
    position: absolute;
    left: 70px;
    top: 70px;
}

.scene3d {
    perspective: 800px;

    width: 240px;
    height: 240px;
    margin-left: auto;
    margin-right: auto;
}

.object3d {
    position: absolute;
    width: inherit;
    height: inherit;
    /*top: 20px;*/
    transform-style: preserve-3d;
}

.smallLogo {
    width: 140px;
    height: 140px;
    /* -webkit-transition: opacity 5s ease-in-out;
    transition: opacity 5s ease-in-out; */
}

#im2E {
    /* top: 30px; */
    transform: translateZ(70px);
}

#im2F {
    /* top: 30px; */
    transform: translateZ(-70px);
}

#im30 {
    /* top: 30px; */
    transform: translateX(70px) rotateY(90deg);
}

#im31 {
    /* top: 30px; */
    transform: translateX(-70px) rotateY(-90deg);
}

#im32 {
    /* top: 30px; */
    transform: translateY(70px) rotateX(90deg);
}

#im33 {
    /* top: 30px; */
    transform: translateY(-70px) rotateX(-90deg);
}

:hover>#im2E {
    top: 20px;
    transform: translateZ(140px);
}

:hover>#im2F {
    top: 20px;
    transform: translateZ(-140px);
}

:hover>#im30 {
    top: 20px;
    transform: translateX(140px) rotateY(90deg);
}

:hover>#im31 {
    top: 20px;
    transform: translateX(-140px) rotateY(-90deg);
}

:hover>#im32 {
    top: 20px;
    transform: translateY(140px) rotateX(90deg);
}

:hover>#im33 {
    top: 20px;
    transform: translateY(-140px) rotateX(-90deg);
}




div#obj6 {
    animation: rotate3d7 linear infinite 14s;
}


@keyframes rotate3d7 {
    from {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }

    to {
        transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
    }
}

div#obj6 img {
    transition: 0.5s linear;
}