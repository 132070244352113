$myFont: Helvetica,
sans-serif;
$myColor: #000000;
$myFontSize: 18px;
$myWidth: 680px;

$palLightShade: #fafafa;
$palLightAccent: #8b9ea5;
$palApple: #4bb334 !global;
$palDarkAccent: #756d73;
$palDarkShade: #211f27;

$containerWidth: 1110px;