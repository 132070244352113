@import "_variables.scss";

.paragraph {
    font-size: 20px;
    text-align: justify;
}

.divider {
    font-size: 20px;
    text-align: center;
}

.quote {
    font-size: 20px;
    text-align: center;
    padding-left: 5rem;
    padding-right: 5rem;
    font-style: italic;
}

.quoteblock {
    font-size: 18px;
    text-align: justify;
    padding-left: 3rem;
    padding-right: 3rem;
}

.caption {
    font-size: 15px;
    text-align: center;
    padding-left: 5rem;
    padding-right: 5rem;
}

.bookImage {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    /* text-align: center; */
}

.TOC {
    color: blue;
    font-size: 22px;
}

.TOC:hover {
    text-decoration: underline;
    cursor: pointer;
}

.bookCrumbs {
    /* padding-right: 1rem; */
    //color: blue;
    color: white;
    font-size: 18px;
    background-color: $palLightAccent;
    //font-style: italic;
    margin: 2px;
}

.bookCrumbsActive {
    color: white;
    font-size: 18px;
    background-color: $palApple;
    font-weight: bold;
    //text-decoration: underline;
}

.bookCrumbs:hover {
    background-color: $palApple;
    cursor: pointer;
    //text-decoration: underline;
}

.bookCrumbDivider {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-size: 18px;
    color: blue;
    font-weight: bold;
}

.bookTitle {
    font-size: 42px;
    font-weight: bold;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.stext {
    font-size: 20px;
}



.documentToC {
    width: 60%;
    margin: 0 auto;
    /* align-content: center;
    display: inline-block; */
}

.TOCHolder {
    //text-overflow: ellipsis;
    overflow: hidden;
}

.TOCInternal {
    justify-content: left;
    font-size: 20px;
    cursor: pointer;
    display: inline-block;
}

.TOCHover {
    //text-decoration: underline;
    //text-decoration-color: white;
    background-color: white;
    //color: black;
    transition: color 0.25s, background-color 0.5s;
}

.TOCHover:hover {
    background-color: $palApple;
    //color: $palLightShade //text-decoration-color: $palApple;
}

.documentText {
    //font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 20px;
    //letter-spacing: 0.5px;
}

.documentTitle {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    display: inline-block;
    font-weight: bold;
    color: black;
    font-size: 48px;
    border-bottom: 6px solid $palApple;
    border-top: 6px solid $palApple;
    //text-align: center;
    //justify-content: center;
    //margin: auto;
    //text-shadow: 1px 1px 1px $palLightAccent;
}

.documentHeading1 {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-variant: small-caps;
    font-weight: bold;
    color: Black;

    border-bottom: 4px solid $palApple;
    margin-bottom: 10px;
    font-size: 38px;
    //text-shadow: 1px 1px 1px $palLightAccent;

}

.documentHeading2 {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-variant: small-caps;
    font-weight: bold;
    color: black;
    font-size: 30px;
    border-bottom: 3px solid $palApple;
    margin-bottom: 6px;
    display: inline-block;
}

.documentHeading3 {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    //font-variant: small-caps;
    font-weight: bold;
    color: $palApple;
    font-size: 25px;

}